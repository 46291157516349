<template>
  <div v-if="!$_loading_state" class="p-giftDetail">
    <div class="p-giftDetail__headImage">
      <img v-if="imageUrl" :src="imageUrl" :alt="title" />
      <NoImage v-else />
    </div>
    <div
      class="c-section c-section--fluid c-section--white01Bg c-section--mb20">
      <div class="c-section__inner">
        <div class="p-giftDetail__header">
          <div class="p-giftDetail__subtitle">{{ rewardTypeLabel }}</div>
          <div class="p-giftDetail__title c-title">
            {{ title }}
          </div>
        </div>
        <div class="p-giftDetail__content">{{ content }}</div>

        <div v-if="isTypeCoupon" class="p-giftDetail__couponCode">
          <div class="p-giftDetail__couponCode__value">{{ couponCode }}</div>
          <Button
            :class="{ 'is-active': isCopied }"
            style-type="action"
            fluid
            squared
            bordered
            flat
            @click="copyCouponCode">
            <template v-slot:iconPrepend>
              <Icon :name="isCopied ? 'checkCircleBordered' : 'copy'" />
            </template>
            {{
              isCopied
                ? 'クーポンコードをコピーしました！'
                : 'クーポンコードをコピーする'
            }}
          </Button>
        </div>

        <div class="p-giftDetail__linkEc">
          <p class="p-giftDetail__hint">
            ※ご利用にはアプリとオンラインストアの連携が必要です
          </p>
          <a :href="linkEcUrl" class="c-textButton c-textButton--sm">
            <Icon
              name="link"
              class="c-textButton__icon c-textButton__icon--prepend" />
            オンラインストアと連携する
          </a>
        </div>
      </div>
    </div>

    <div v-if="notes" class="c-section">
      <div class="p-giftDetail__notes">
        <h3 class="p-giftDetail__notes__title">注意事項</h3>
        <p class="p-giftDetail__notes__content">{{ notes }}</p>
      </div>
    </div>

    <div class="p-giftDetail__actions c-section">
      <Button
        v-if="isTypePoint"
        :style-type="chargeButtonStyle"
        fluid
        :disabled="chargeButtonStyle === 'disabled'"
        @click="chargePoint">
        <template v-slot:iconPrepend>
          <Icon name="point" />
        </template>
        {{ chargeButtonLabel }}
      </Button>
      <Button v-if="isTypeCoupon" style-type="primary" fluid :href="ecUrl">
        <template v-slot:iconPrepend>
          <Icon name="cartOff" />
        </template>
        オンラインストアで使用する
      </Button>
    </div>
  </div>
</template>

<script>
import { RewardType, SystemDialogMessage } from '@/constants/enums';

export default {
  data: () => ({
    isCopied: false,
    present: null,
    /** @type { 'READY' | 'CHARGING' | 'DONE' } */
    pointChargeState: 'READY'
  }),

  computed: {
    presentId() {
      return this.$route.params.id;
    },

    title() {
      return this.present?.reward?.name;
    },

    rewardType() {
      return this.present?.reward?.type;
    },

    rewardTypeLabel() {
      return RewardType.getTypeName(this.rewardType);
    },

    isTypePoint() {
      return this.rewardType === RewardType.POINT;
    },

    isTypeCoupon() {
      return this.rewardType === RewardType.COUPON;
    },

    content() {
      return this.present?.reward?.description;
    },

    imageUrl() {
      return this.present?.reward?.image_url;
    },

    notes() {
      return this.present?.reward?.warning;
    },

    couponCode() {
      return this.present?.reward?.coupon_code;
    },

    linkEcUrl() {
      return this.$customUrlScheme.page('standalone', 'url=/link/onlinestore');
    },

    ecUrl() {
      return this.$customUrlScheme.page('ec');
    },

    chargeButtonStyle() {
      return this.pointChargeState === 'READY' ? 'primary' : 'disabled';
    },

    chargeButtonLabel() {
      if (this.pointChargeState === 'CHARGING') return '処理中...';
      if (this.pointChargeState === 'DONE') return 'ポイントをチャージしました';
      return 'ポイントをチャージする';
    }
  },

  created() {
    this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.$_loading_start();

        const { data } = await this.$repositories('present').getPresent(
          this.presentId
        );
        this.present = data;
      } catch {
        window.location.href = this.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        this.$_loading_stop();
      }
    },

    async copyCouponCode() {
      await this.$utilities.copy(this.couponCode);
      this.isCopied = true;
    },

    async chargePoint() {
      if (this.pointChargeState !== 'READY') return;

      try {
        this.pointChargeState = 'CHARGING';
        await this.$repositories('present').usePresent(this.presentId);
        this.pointChargeState = 'DONE';
      } catch {
        window.location.href = this.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
        this.pointChargeState = 'READY';
      }
    }
  }
};
</script>
