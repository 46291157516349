var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$_loading_state
    ? _c("div", { staticClass: "p-giftDetail" }, [
        _c(
          "div",
          { staticClass: "p-giftDetail__headImage" },
          [
            _vm.imageUrl
              ? _c("img", { attrs: { src: _vm.imageUrl, alt: _vm.title } })
              : _c("NoImage"),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "c-section c-section--fluid c-section--white01Bg c-section--mb20",
          },
          [
            _c("div", { staticClass: "c-section__inner" }, [
              _c("div", { staticClass: "p-giftDetail__header" }, [
                _c("div", { staticClass: "p-giftDetail__subtitle" }, [
                  _vm._v(_vm._s(_vm.rewardTypeLabel)),
                ]),
                _c("div", { staticClass: "p-giftDetail__title c-title" }, [
                  _vm._v(" " + _vm._s(_vm.title) + " "),
                ]),
              ]),
              _c("div", { staticClass: "p-giftDetail__content" }, [
                _vm._v(_vm._s(_vm.content)),
              ]),
              _vm.isTypeCoupon
                ? _c(
                    "div",
                    { staticClass: "p-giftDetail__couponCode" },
                    [
                      _c(
                        "div",
                        { staticClass: "p-giftDetail__couponCode__value" },
                        [_vm._v(_vm._s(_vm.couponCode))]
                      ),
                      _c(
                        "Button",
                        {
                          class: { "is-active": _vm.isCopied },
                          attrs: {
                            "style-type": "action",
                            fluid: "",
                            squared: "",
                            bordered: "",
                            flat: "",
                          },
                          on: { click: _vm.copyCouponCode },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "iconPrepend",
                                fn: function () {
                                  return [
                                    _c("Icon", {
                                      attrs: {
                                        name: _vm.isCopied
                                          ? "checkCircleBordered"
                                          : "copy",
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3589503436
                          ),
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isCopied
                                  ? "クーポンコードをコピーしました！"
                                  : "クーポンコードをコピーする"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "p-giftDetail__linkEc" }, [
                _c("p", { staticClass: "p-giftDetail__hint" }, [
                  _vm._v(
                    " ※ご利用にはアプリとオンラインストアの連携が必要です "
                  ),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "c-textButton c-textButton--sm",
                    attrs: { href: _vm.linkEcUrl },
                  },
                  [
                    _c("Icon", {
                      staticClass:
                        "c-textButton__icon c-textButton__icon--prepend",
                      attrs: { name: "link" },
                    }),
                    _vm._v(" オンラインストアと連携する "),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
        _vm.notes
          ? _c("div", { staticClass: "c-section" }, [
              _c("div", { staticClass: "p-giftDetail__notes" }, [
                _c("h3", { staticClass: "p-giftDetail__notes__title" }, [
                  _vm._v("注意事項"),
                ]),
                _c("p", { staticClass: "p-giftDetail__notes__content" }, [
                  _vm._v(_vm._s(_vm.notes)),
                ]),
              ]),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "p-giftDetail__actions c-section" },
          [
            _vm.isTypePoint
              ? _c(
                  "Button",
                  {
                    attrs: {
                      "style-type": _vm.chargeButtonStyle,
                      fluid: "",
                      disabled: _vm.chargeButtonStyle === "disabled",
                    },
                    on: { click: _vm.chargePoint },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "iconPrepend",
                          fn: function () {
                            return [_c("Icon", { attrs: { name: "point" } })]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      605848887
                    ),
                  },
                  [_vm._v(" " + _vm._s(_vm.chargeButtonLabel) + " ")]
                )
              : _vm._e(),
            _vm.isTypeCoupon
              ? _c(
                  "Button",
                  {
                    attrs: {
                      "style-type": "primary",
                      fluid: "",
                      href: _vm.ecUrl,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "iconPrepend",
                          fn: function () {
                            return [_c("Icon", { attrs: { name: "cartOff" } })]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1386353712
                    ),
                  },
                  [_vm._v(" オンラインストアで使用する ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }